<script lang="ts">
  import MaterialIcon from '$lib/MaterialIcon.svelte';
  import { closeModal, openModalId } from '$lib/stores/modals.store';
  import { mdiClose } from '@mdi/js';
  import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    Transition,
    TransitionChild,
  } from '@rgossiaux/svelte-headlessui';
  import { twMerge } from 'tailwind-merge';
  import { createEventDispatcher } from 'svelte';
  /**
   * Each modal is assigned an ID. Modals are opened by calling the
   * modals.store.ts `openModal(modalId: string)` function.
   */
  export let modalId: string;
  export let closeButton = true;
  export let dismissable = true;

  const dispatch = createEventDispatcher<{
    open: null;
    close: null;
  }>();

  let clazz: string = '';
  let hasBeenOpened = false;

  $: open = $openModalId === modalId;

  $: if (open) {
    hasBeenOpened = true;
    dispatch('open');
  }

  $: if (!open && hasBeenOpened) {
    dispatch('close');
  }

  export { clazz as class };

  const close = () => {
    closeModal();
  };

  /**
   * Trap keyboard events when dialog is not dismissable.
   */
  function handleKeyDown(event: KeyboardEvent) {
    if (event.key !== 'Escape') return;
    if (!open) return;
    if (dismissable) return;
    event.stopPropagation();
  }
</script>

<svelte:body on:keydown={handleKeyDown} />

<div class="absolute">
  <Transition show={open}>
    <Dialog
      {open}
      on:close={close}
      class="fixed z-50 inset-0 overflow-y-auto md:py-12 xs:py-8 xs:px-4 scroll-m-0 font-helvetica"
    >
      <TransitionChild
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-75"
        leave="ease-in duration-200"
        leaveFrom="opacity-75"
        leaveTo="opacity-0"
        entered="opacity-75"
      >
        <!-- When it's not dismissable we set pointer-events-none to true -->
        <DialogOverlay
          class="fixed inset-0 bg-black opacity-100 {!dismissable ? 'pointer-events-none' : ''}"
        />
      </TransitionChild>
      <TransitionChild
        enter="ease-out transform duration-300"
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        leave="ease-in transform duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <div
          class={twMerge(`
        relative
        mx-auto
        text-gray
        card
        py-3
        ${clazz}
        `)}
        >
          {#if closeButton && dismissable}
            <button
              on:click={close}
              class="
                absolute
                right-0
                top-0
                text-gray-200
                high-contrast:text-gray-800
                p-2
              "
            >
              <MaterialIcon size="1rem" path={mdiClose} label="Close Modal Dialog" />
              <span class="sr-only">Close Dialog</span>
            </button>
          {/if}
          {#if $$slots.title}
            <DialogTitle class="text-center"><slot name="title" /></DialogTitle>
          {/if}
          <slot />
        </div>
      </TransitionChild>
    </Dialog>
  </Transition>
</div>
